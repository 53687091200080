import { equals } from '@/node_modules/@osp/design-system/assets/js/utilities/compare';
import { setSafeTimeout } from '@/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { IClsBaseMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-base-mixin';
import {
	ILcpOptimizationMixin,
	LcpOptimizationMixin,
} from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import {
	ClsContainerMixin,
	IClsContainerMixin,
} from '@/node_modules/@osp/design-system/components/mixins/cls-container-mixin';
import { ContentPageMixin } from './content-page-mixin';
import { Component, Mixins, Watch } from '@/app-utils/decorators';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useUxStore } from '~/@api/store/uxApi';
import BasePage from '~/pages/base-page/base-page';
import { TrackingMixin } from '~/pages/tracking.mixin';
import ContentSlot from '~/components/molecules/content-slot';

@Component({
	extends: BasePage,
	components: { ContentSlot },
	mixins: [LcpOptimizationMixin, ClsContainerMixin, TrackingMixin, ContentPageMixin],
})
export default class ContentPage extends Mixins<
	ContentPageMixin & IClsBaseMixin & ILcpOptimizationMixin & IClsContainerMixin
>(ContentPageMixin, LcpOptimizationMixin) {
	private renderKey = Date.now();
	private contentUpdateOngoing = useUxStore(this.$store).state.pageSwitchInProgress;

	get showContentNavigation(): boolean {
		return !this.BREAKPOINT.IS_MOBILE && this.navigation && !!this.navigation.length;
	}

	get is404Page(): boolean {
		return useRoutingStore(this.$store).state.spaData.datalayer.page.pageInfo.pageID === 'notFound';
	}

	get pageSwitchInProgress() {
		return useUxStore(this.$store).state.pageSwitchInProgress;
	}

	@Watch('contentSlots', { deep: true })
	contentSlotsWatcher(newContentSlots, oldContentSlots) {
		if (equals(newContentSlots, oldContentSlots)) return;

		this.contentUpdateOngoing = true;

		const { api: uxApi } = useUxStore(this.$store);

		uxApi.setFooterVisible(false);
		uxApi.setPageSwitchProgressStatus(true);
	}

	@Watch('pageSwitchInProgress')
	pageSwitchWatcher(switchInProgress) {
		if (!switchInProgress) {
			setSafeTimeout(() => {
				this.contentUpdateOngoing = false;
			}, this.clsData.config.fallbackTimerMs);
		}
	}

	created() {
		(this as ILcpOptimizationMixin).lcp.optimizationDesktop = true;
	}
}
