import Vue from 'vue';
import { Route } from 'vue-router';
import { clientInitTrackingStore } from '~/@api/store/trackingApi';
import {
	importRunTask,
	importPerformanceTracking,
	importTrackingApi,
} from '~/app-utils/dynamic-imports';
import { performanceTracking } from '~/app-utils/tracking.utils';

export const TrackingMixin = Vue.extend({
	beforeRouteEnter(to: Route, _from: Route, next): void {
		// Set page label before the route is entered
		const pageType =
			to.meta?.pageType ||
			to.meta?.spaType ||
			to.meta?.spaOtherId ||
			to.path?.split('/')?.slice(-1)?.pop() ||
			'UNKNOWN';

		performanceTracking?.setLabel(undefined, pageType);

		next();
	},
	beforeRouteLeave(_to: Route, from: Route, next): void {
		// Only perform lux send when arrived to no leaving page already via SPA navigation
		// (For initial page calls we perform lux send when several other conditions are met, triggered by a vuex module)
		// Before route leave, send the RUM data, if it was not a device switch redirect
		if (!from.fullPath.includes('force-device')) {
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					performanceTracking.luxSend(this.$lux, from.meta.pageType);
				});
			});
		}

		// Start a new LUX performance tracking session
		performanceTracking.init(this.$lux);

		next();
	},
	data: function () {
		return {
			lux: {
				sent: false,
			},
		};
	},
	watch: {
		'clsData.isFinished': {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.handleClsFinished();
				}
			},
		},
		'$store.state.tracking.performance.unresolvedMarks'(newValue, oldValue) {
			if (oldValue > 0 && newValue === 0) {
				importRunTask().then(({ runTask }) => {
					importTrackingApi().then(({ useTrackingStore }) => {
						runTask(() => useTrackingStore(this.$store).api.registerAllMarksFinished());
					});
				});
			}
		},
		'$store.state.tracking.performance.readyToSend'(newValue, oldValue) {
			if (newValue && !oldValue && !this.lux.sent) {
				this.lux.sent = true;

				importRunTask().then(({ runTaskWithPromise }) => {
					importPerformanceTracking().then(async ({ performanceTracking }) => {
						await runTaskWithPromise(async () => {
							performanceTracking.loadingFinished(this.$lux);
							await Promise.resolve();
						});

						performanceTracking.luxSend(this.$lux, this.$route.meta.pageType);
					});
				});
			}
		},
	},
	beforeCreate() {
		clientInitTrackingStore(this.$store);
	},
	mounted() {
		performanceTracking.markLoadTime(this.$lux);

		performanceTracking.ensurePageLabel(
			this.$lux,
			this.$nuxt.context.from?.matched
				?.find((match) => match.meta?.pageType)
				?.meta?.pageType?.toString(),
		);

		if (!this.$nuxt.from) {
			importRunTask().then(({ runTask, runTaskWithPromise }) => {
				runTask(() => {
					importTrackingApi().then(async ({ useTrackingStore }) => {
						const { api: trackingApi } = useTrackingStore(this.$store);

						await runTaskWithPromise(async () => {
							await trackingApi.reportSsrPerformanceMarks();
						});

						runTask(() => {
							trackingApi.registerEventRenderingFinished();
						});
					});
				});
			});
		}
	},
	methods: {
		handleClsFinished() {
			Promise.all([importTrackingApi(), clientInitTrackingStore(this.$store)]).then(
				([{ useTrackingStore }]) => {
					const { api: trackingApi, state: trackingState } = useTrackingStore(this.$store);

					if (process.client && !trackingState.performance.reportedEvents.clsControlFinished) {
						importRunTask().then(({ runTask }) => {
							runTask(() => trackingApi.registerEventClsFinished());
						});
					}
				},
			);
		},
	},
});
