var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.renderKey,class:{
		'tw-min-h-screen': !_vm.is404Page,
		'cls-hidden': _vm.pageSwitchInProgress || _vm.contentUpdateOngoing,
	},attrs:{"id":"content-page","data-labelOrId":_vm.pageIdentifier}},[(_vm.isStructuredContent)?[_c('Breadcrumb',_vm._b({staticClass:"tw-mb-2 legacy-layout",on:{"clsEvent":_vm.handleClsEvent}},'Breadcrumb',_vm.breadcrumbData,false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('LazySideNavigationBar',{attrs:{"entries":_vm.navigation,"without-selected":!_vm.BREAKPOINT.IS_MOBILE,"nav-label":"content"}})],1),(_vm.showContentNavigation)?_c('div',{staticClass:"legacy-layout",class:{ 'cls-hidden': !_vm.clsStatus('Breadcrumb') }},[_c('LazyContentNavigation',{attrs:{"data":_vm.navigation},on:{"clsEvent":_vm.handleClsEvent}})],1):_vm._e(),_c('div',{staticClass:"content-wrapper"},_vm._l((_vm.contentSlots),function(contentSlot,index){return _c('ContentSlot',{key:contentSlot.id,staticClass:"content",attrs:{"id":contentSlot.id,"component-preload-count":contentSlot.componentPreloadCount,"content-preview-image-optimization":_vm.previewImageOptimization,"cls":{
					key: ("ContentSlot_" + index),
					condition:
						(_vm.clsStatus('ContentNavigation') || !_vm.showContentNavigation) &&
						(index === 0 || _vm.clsStatus(("ContentSlot_" + (index - 1)))),
				}},on:{"clsEvent":_vm.handleClsEvent}})}),1)]:_vm._l((_vm.contentSlots),function(contentSlot,index){return _c('ContentSlot',{key:contentSlot.id,attrs:{"id":contentSlot.id,"component-preload-count":contentSlot.componentPreloadCount,"content-preview-image-optimization":_vm.previewImageOptimization,"cls":{
				key: ("ContentSlot_" + index),
				condition: index === 0 || _vm.clsStatus(("ContentSlot_" + (index - 1))),
			}},on:{"clsEvent":_vm.handleClsEvent}})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clsControlShouldHappen && !_vm.clsData.allSubComponentsVisible),expression:"clsControlShouldHappen && !clsData.allSubComponentsVisible"}],staticClass:"tw-h-screen tw-w-1"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }