import { render, staticRenderFns } from "./content-page.vue?vue&type=template&id=204ec566&scoped=true&"
import script from "./content-page.ts?vue&type=script&lang=ts&"
export * from "./content-page.ts?vue&type=script&lang=ts&"
import style0 from "./content-page.scss?vue&type=style&index=0&id=204ec566&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204ec566",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Breadcrumb/Breadcrumb.vue').default,ContentSlot: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/components/molecules/content-slot.tsx').default})
