import { PropType } from 'vue';
import { importRunTask } from '../../assets/js/utilities/dynamicImports';
import { ClsBaseMixin, ClsEventType } from './cls-base-mixin';

export interface IClsProps {
	condition: boolean;
	key?: string;
	disabled?: false;
}

export interface IClsComponentMixin {
	cls: IClsProps;
}

export interface IClsComponentMixinExtended extends IClsComponentMixin {
	isServerSideRendered(): boolean;
}

/**
 * Use this mixin to provide CLS optimization base functionality to a component
 */
export const ClsComponentMixin = ClsBaseMixin.extend({
	props: {
		cls: {
			type: Object as PropType<IClsComponentMixin['cls']>,
		},
	},
	watch: {
		'cls.condition'(value) {
			this.clsData.condition = value;
		},
	},
	created() {
		// If cls component was rendered server side, add html attribute to avoid execution of cls logic for it on client
		if (process.server && this.$attrs) {
			try {
				this.$attrs['data-ssr'] = 'true';
			} catch {}
		}

		if (!this.clsControlShouldHappen) return;

		if (!this.clsData?.key) {
			this.clsData.key =
				this.cls?.key ||
				(this.$options?.propsData as IClsComponentMixin)?.cls?.key ||
				this.$vnode?.componentOptions?.tag?.indexOf('Lazy') === 0
					? this.$vnode?.componentOptions?.tag?.replace('Lazy', '')
					: this.$vnode?.componentOptions?.tag;
		}

		if (!this.clsData.isRegistered) {
			this.emitClsEvent(ClsEventType.register);
		}
	},
	beforeMount() {
		if (!this.clsControlShouldHappen) return;

		importRunTask().then(({ runTask }) => {
			runTask(() => {
				this._createWatcher();
			});
		});
	},
	methods: {
		_createWatcher() {
			this.$watch(
				'cls.condition',
				(newValue: boolean) => {
					this.clsData.condition = newValue;
				},
				{ immediate: true },
			);
		},
		isServerSideRendered(): boolean {
			return this.isSSR;
		},
	},
});
